import React from "react";
import Menu from "../../component/Menu";
import "./index.scss";
import { Link } from "react-router-dom";

interface Props {
  hiddenMenu?: boolean;
}

const Navbar: React.FC<Props> = ({ hiddenMenu }) => {
  const handleToogleNavBar = () => {
    const navBarEle = document.querySelector("#navbarTogglerDemo02");
    if (navBarEle) {
      navBarEle.classList.toggle("show");
    }
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-light pt-3 text-white">
      <Link
        className="logo text-white h2 mb-0 font-weight-bold font-italic text-decoration-none"
        to="/"
      >
        <img src="/assets/images/logo.png" alt="" width={150} />
      </Link>

      {hiddenMenu || (
        <>
          <button
            className="navbar-toggler"
            onClick={handleToogleNavBar}
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="toggle-icon">
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                className="bi bi-three-dots-vertical"
                fill="#ffffff"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                />
              </svg>
            </span>
          </button>
          <Menu />
        </>
      )}
    </nav>
  );
};

export default Navbar;
