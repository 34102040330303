import React from "react";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { getAppInfoSelector } from "../../redux/selectors";
import "./index.scss";

const Features: React.FC = () => {
  const data = useSelector(getAppInfoSelector)?.Features;
  return (
    <>
      {data && (
        <section id="features" className="features mt-5">
          <div className="container">
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 7000,
                disableOnInteraction: false,
              }}
              navigation={true}
              modules={[Pagination, Autoplay]}
              className="mySwiper"
            >
              {data.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="row">
                      <div className="col-12 col-lg-6 col-md-6">
                        <img src={item.Image} alt="" />
                      </div>
                      <div className="col-12 col-lg-6 col-md-6 d-flex flex-column my-auto">
                        <h2 dangerouslySetInnerHTML={{ __html: item.Title }} />
                        <p
                          dangerouslySetInnerHTML={{ __html: item.Descripton }}
                        ></p>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </section>
      )}
    </>
  );
};

export default Features;
