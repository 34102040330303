import { RootState } from "./store";

// APP INFO
export const getAppInfoSelector = (state: RootState) => state.appInfo.data;

// TERMS
export const getTermsSelector = (state: RootState) => state.terms.data;

// POLICIES
export const getPoliciesSelector = (state: RootState) => state.policies.data;

// FAQs
export const getFAQsSelector = (state: RootState) => state.faqs.data;

// LOADING
export const getIsLoadingSelector = (state: RootState) =>
  state.loading.isLoading;
