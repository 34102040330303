import React, { useState } from "react";
import "./index.scss";

const ButtonGoToTop: React.FC = () => {
  const [displayBtn, setDisplayBtn] = useState("");

  // When the user scrolls down 100px from the top of the document, show the button
  window.onscroll = (): void => {
    scrollFunction();
  };

  function scrollFunction(): void {
    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100
    ) {
      setDisplayBtn("block");
    } else {
      setDisplayBtn("none");
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  function handleGoToTop(): void {
    document.body.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
  return (
    <button
      type="button"
      className="btn btn-lg bg-green border-0"
      id="btn-back-to-top"
      onClick={handleGoToTop}
      style={{ display: displayBtn }}
    >
      <img
        src="./assets/svg/arrow-up-solid.svg"
        width="10px"
        height="15px"
        alt=""
      />
    </button>
  );
};
export default ButtonGoToTop;
