// API GET TERMS

import axiosInstance from "../axiosInstance";

const termsAPI = () => {
  return axiosInstance.post("/terms", {
    source: "my-wifi",
  });
};

export default termsAPI;
