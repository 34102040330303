import React from "react";
// import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAppInfoSelector } from "../redux/selectors";
import Navbar from "../component/Navbar";

const LoginWifiPage: React.FC = () => {
  // let { cardInfo } = useParams();
  const data = useSelector(getAppInfoSelector)?.Main;
  const linkIOs = useSelector(getAppInfoSelector)?.URLIOS;
  const linkAndroid = useSelector(getAppInfoSelector)?.URLAndroid;
  const qrCode = useSelector(getAppInfoSelector)?.QRCode;
  return (
    <>
      {data && (
        <div className="bg-green" style={{ minHeight: "100vh" }}>
          <div className="bg-image anm" data-speed-x="10" data-speed-y="10" data-speed-rotate="-10">
            <img src={data.Background} alt="" />
          </div>

          <div className="container text-white">
            <Navbar hiddenMenu />
            <div className="row ml-0 mt-5">
              <div className="col-12 col-lg-8 col-md-8">
                <h2 dangerouslySetInnerHTML={{ __html: data.Title }} />
                <div className="text-left">
                  <p className="mb-0">
                    Sử dụng app MyWIFI quét mã QR để lưu, quản lý thẻ và tự động đăng nhập những lần
                    sau.
                  </p>
                  <p className="mb-0">Các tính năng của app MyWIFI: </p>
                  <p className="mb-0">&nbsp;&nbsp;- Mua thẻ nhanh, tiện lợi, 24/7, ...</p>
                  <p className="mb-0">&nbsp;&nbsp;- Quản lý đơn hàng đã thanh toán</p>
                  <p className="mb-0">&nbsp;&nbsp;- Quản lý, sử dụng thẻ đã mua</p>
                </div>
                <div className="download-buttons mt-2 mb-3">
                  <p className="text-left">Tải ứng dụng trên điện thoại</p>
                  <div
                    className="d-flex justify-content-center justify-content-md-start mt-2 flex-wrap"
                    style={{ gap: "5px" }}
                  >
                    <div className="bg-white h-100 mb-1 mb-sm-0">
                      <img className="rounded-icon" src={qrCode} width={130} height={130} alt="" />
                    </div>
                    <div
                      className="d-flex flex-column justify-content-center"
                      style={{ gap: "5px" }}
                    >
                      <a href={linkIOs} className="download-app text-white d-block mx-2 mx-md-0">
                        <div className="appstore">
                          <div className="card border-white rounded bg-green d-flex flex-row align-items-center  px-2 text-center">
                            <div className="icon float-left align-items-center">
                              <svg
                                width="2rem"
                                height="2rem"
                                viewBox="0 0 16 16"
                                className="bi bi-phone"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M11 1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"
                                />
                                <path fillRule="evenodd" d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                              </svg>
                            </div>
                            <div className="content float-right ml-1">
                              <small>Available on the</small>
                              <h5>App Store</h5>
                            </div>
                          </div>
                        </div>
                      </a>
                      <a
                        href={linkAndroid}
                        className="download-app text-white d-block mx-2 mx-md-0"
                      >
                        <div className="playstore">
                          <div className="card border-white rounded bg-green d-flex flex-row align-items-center px-2 text-center">
                            <div className="icon float-left align-items-center">
                              <svg
                                width="2rem"
                                height="2rem"
                                viewBox="0 0 16 16"
                                className="bi bi-play-fill"
                                fill="#ffffff"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
                              </svg>
                            </div>
                            <div className="content float-right ml-1">
                              <small>Android App on</small>
                              <h5>Google Play</h5>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <img
                  src="/assets/images/InstructionFScanQRCode.png"
                  className="w-100"
                  style={{ objectFit: "contain", maxHeight: 500 }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginWifiPage;
