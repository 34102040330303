import React from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import { getAppInfoSelector } from "../../redux/selectors";

const Plan: React.FC = () => {
  const data = useSelector(getAppInfoSelector)?.Plan;
  return (
    <>
      {data && (
        <div id="plan" className="plan bg-shade text-center pt-5">
          <div className="container  pt-5">
            <div
              className="plan-bg-image anm"
              data-speed-x="10"
              data-speed-rotate="-10"
            >
              <img className="text-center" src={data.Background} alt="" />
            </div>
            <div className="row">
              <div className="col-12 col-lg-8 m-auto">
                <h2 dangerouslySetInnerHTML={{ __html: data.Title }} />
                <p dangerouslySetInnerHTML={{ __html: data.Descripton }} />
                <img className="mt-5 text-center" src={data.Image} alt="" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Plan;
