import { createSlice } from "@reduxjs/toolkit";

export interface State {
  isLoading: boolean;
  time: number;
}

const initialState: State = { isLoading: false, time: 0 };

export const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoading: (state, action: { type: string; payload: boolean }) => {
      if (action.payload) {
        state.isLoading = action.payload;
        state.time += 1;
      } else {
        state.time === 1 ? (state.isLoading = false) : (state.isLoading = true);
        state.time -= 1;
      }
    },
  },
});

export const { setLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
