import React, { useEffect, useState } from "react";
import "./index.scss";

interface FAQItem {
  Question: string;
  Answer: string;
}

interface FAQCategory {
  Title: string;
  Key: string;
  Items: FAQItem[];
}

interface Props {
  FAQsData: FAQCategory[];
}

const FAQs: React.FC<Props> = ({ FAQsData }) => {
  const [activeCategory, setActiveCategory] = useState<FAQCategory>();

  const handleToggleQuestion = (index: number) => {
    const questionItem: HTMLElement | null = document.querySelector(
      `#FAQs-question-${index}`
    );
    questionItem && questionItem.classList.toggle("active");
  };

  useEffect(() => {
    if (FAQsData.length > 0) {
      setActiveCategory(FAQsData[0]);
    }
  }, [FAQsData]);

  useEffect(() => {
    const questionItemIsActive: NodeListOf<HTMLElement> =
      document.querySelectorAll(".FAQs__question-answer-list__item.active");
    questionItemIsActive.forEach((item) => {
      item.classList.remove("active");
    });
  }, [activeCategory]);

  return (
    <div className="FAQs my-4 py-2">
      <h4 className="text-center font-weight-bold mb-4">Hướng dẫn sử dụng</h4>
      <div className="row">
        {/* FAQs Category */}
        <div className="col-12 col-md-4">
          <ul className="FAQs__category d-flex d-md-block overflow-auto hidden-scrollbar">
            {FAQsData.map((item: FAQCategory) => {
              return (
                <li
                  key={item.Key}
                  className={`FAQs__category__item ${
                    item.Key === activeCategory?.Key ? "active text-green" : ""
                  }`}
                  onClick={() => setActiveCategory(item)}
                >
                  <span className="font-weight-normal">{item.Title}</span>
                </li>
              );
            })}
          </ul>
        </div>
        {/* List FAQs of Active Category */}
        <div className="col-12 col-md-8">
          <div className="FAQs__question-answer-list">
            {activeCategory &&
              activeCategory.Items.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="mb-2 FAQs__question-answer-list__item"
                    id={`FAQs-question-${index}`}
                    onClick={() => handleToggleQuestion(index)}
                  >
                    <div className="d-flex cursor-pointer FAQs__item__question">
                      <img
                        src="./assets/svg/angles-right-solid.svg"
                        alt=""
                        width={12}
                      />
                      <p className="mb-0 ml-2">{item.Question}</p>
                    </div>
                    <div
                      className="ml-4 FAQs__item__answer text-left"
                      dangerouslySetInnerHTML={{ __html: item.Answer }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
