import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";

const Menu: React.FC = () => {
  return (
    <div className="menu collapse navbar-collapse" id="navbarTogglerDemo02">
      <ul className="navbar-nav text-center ml-auto mt-2 mt-lg-0">
        <li className="nav-item text-center">
          <a className="nav-link" href="#hero">
            About
          </a>
        </li>
        <li className="nav-item text-center">
          <a className="nav-link" href="#features">
            Features
          </a>
        </li>
        <li className="nav-item text-center">
          <a className="nav-link" href="#plan">
            Reviews
          </a>
        </li>
        <li className="nav-item text-center">
          <a className="nav-link" href="#manage">
            Cards
          </a>
        </li>
        <li className="nav-item text-center">
          <Link className="get-app btn" to="/download">
            Get App
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
