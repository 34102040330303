import React from "react";
import Header from "../component/Header";
import Features from "../component/Features";
import Plan from "../component/Plan";
import Manage from "../component/Manage";
import Footer from "../component/Footer";
import { useSelector } from "react-redux";
import { getAppInfoSelector } from "../redux/selectors";
import Navbar from "../component/Navbar";
import Company from "../component/Company";

const LandingPage: React.FC = () => {
  const appInfoData = useSelector(getAppInfoSelector);
  return (
    <>
      {appInfoData ? (
        <div className="langding-page">
          <Header />
          <Features />
          <Plan />
          <Manage />
          <Footer />
          <Company />
        </div>
      ) : (
        <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
          <div className="bg-green">
            <div className="container">
              <Navbar hiddenMenu />
            </div>
          </div>
          <div className="container flex-grow-1">
            <p className="mt-4 text-center">Không có dữ liệu.</p>
          </div>
          <Company />
        </div>
      )}
    </>
  );
};

export default LandingPage;
