import { createSlice } from "@reduxjs/toolkit";

interface FAQItem {
  Question: string;
  Answer: string;
}

interface FAQCategory {
  Title: string;
  Key: string;
  Items: FAQItem[];
}

export interface State {
  data: FAQCategory[] | undefined;
}

const initialState: State = { data: undefined };

export const faqsSlice = createSlice({
  name: "FAQs",
  initialState,
  reducers: {
    setFAQs: (state, action: { type: string; payload: FAQCategory[] }) => {
      state.data = action.payload;
    },
  },
});

export const { setFAQs } = faqsSlice.actions;

export default faqsSlice.reducer;
