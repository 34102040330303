import { configureStore } from "@reduxjs/toolkit";
import termsReducer from "./slices/termsSlice";
import policiesReducer from "./slices/policiesSlice";
import appInfoReducer from "./slices/appInfoSlice";
import loadingReducer from "./slices/loadingSlice";
import faqsReducer from "./slices/faqsSlice";

export const store = configureStore({
  reducer: {
    terms: termsReducer,
    policies: policiesReducer,
    appInfo: appInfoReducer,
    faqs: faqsReducer,
    loading: loadingReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
