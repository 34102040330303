// API GET POLICIES

import axiosInstance from "../axiosInstance";

const policiesAPI = () => {
  return axiosInstance.post("/policies", {
    source: "my-wifi",
  });
};

export default policiesAPI;
