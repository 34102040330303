import React from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import { getAppInfoSelector } from "../../redux/selectors";

const Manage: React.FC = () => {
  const data = useSelector(getAppInfoSelector)?.Cards;
  return (
    <>
      {data && (
        <section id="manage" className="manage p-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-12 col-lg-6 d-flex flex-column my-auto">
                <h2 dangerouslySetInnerHTML={{ __html: data.Title }} />
                <p
                  className="mr-5"
                  dangerouslySetInnerHTML={{ __html: data.Descripton }}
                />
              </div>
              <div className="col-12 col-lg-6 d-flex flex-column m-auto">
                <div className="food-items">
                  {data.Items.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="second-card rounded-lg mx-auto shadow-sm my-4"
                        style={{ width: "90%" }}
                      >
                        <div className="card d-flex align-items-start align-items-sm-center justify-content-between flex-row px-3 py-2">
                          <div className="item-image" style={{ minWidth: 80 }}>
                            <img
                              style={{ width: "160px !important" }}
                              src={item.Image}
                              alt=""
                            />
                          </div>
                          <div className="item-details mx-3 text-left flex-grow-1">
                            <div className="item-name">
                              <h5 className="text-green text-bold">
                                {item.Title}
                              </h5>
                              <p>{item.Detail}</p>
                              <span className="text-green text-bold">
                                {item.Bandwidth}
                              </span>
                            </div>
                          </div>
                          <div className="time text-right d-none d-sm-block">
                            <h3 className="text-green text-bold">
                              {item.Price}
                            </h3>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Manage;
