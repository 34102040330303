// API GET APP'S INFORMATION

import axiosInstance from "../axiosInstance";

const faqsAPI = () => {
  return axiosInstance.post("/faqs", {
    source: "my-wifi",
  });
};

export default faqsAPI;
