import { createSlice } from "@reduxjs/toolkit";
import { PoliciesType } from "../../types";

export interface State {
  data: PoliciesType | undefined;
}

const initialState: State = { data: undefined };

export const policiesSlice = createSlice({
  name: "policies",
  initialState,
  reducers: {
    setPolicies: (state, action: { type: string; payload: PoliciesType }) => {
      state.data = action.payload;
    },
  },
});

export const { setPolicies } = policiesSlice.actions;

export default policiesSlice.reducer;
