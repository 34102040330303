import React from "react";
import { useSelector } from "react-redux";
import { getAppInfoSelector } from "../../redux/selectors";
import { Link } from "react-router-dom";
import "./index.scss";

const Company: React.FC = () => {
  const dataCompany = useSelector(getAppInfoSelector)?.Company;

  return (
    <>
      {dataCompany && (
        <div className="company py-3 border-top text-white bg-green">
          <div className="container p">
            <div className="row flex-column-reverse flex-md-row">
              <div className="col-12 col-md-9 text-left">
                <p className="mb-0 font-weight-bold">{dataCompany.Name}</p>
                <p className="mb-0">{dataCompany.Address}</p>
                <p className="mb-0">{dataCompany.Phone}</p>
              </div>
              <div className="col-12 col-md-3">
                <div className="d-flex justify-content-start justify-content-md-end">
                  <Link
                    to="/terms"
                    className="mb-0 mr-3 mx-md-3 d-inline-block mb-2 text-white"
                  >
                    Terms
                  </Link>
                  <Link
                    to="/policies"
                    className="mb-0 mx-3 d-inline-block mb-2 text-white"
                  >
                    Policies
                  </Link>
                  <Link
                    to="/FAQs"
                    className="mb-0 mx-3 d-inline-block mb-2 text-white"
                  >
                    FAQs
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Company;
