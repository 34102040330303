import React from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import { getAppInfoSelector } from "../../redux/selectors";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  const dataFooter = useSelector(getAppInfoSelector)?.Footer;
  return (
    <>
      {dataFooter && (
        <section
          id="footer"
          className="footer text-white text-center py-0"
          style={{
            background: `url(${dataFooter.Image}) no-repeat,
          #2ecc71`,
            backgroundPosition: "center center",
            backgroundSize: "contain",
          }}
        >
          <div className="container py-5">
            <div className="row">
              <div className="col-12 col-lg-8 m-auto">
                <h2
                  className="mb-3"
                  dangerouslySetInnerHTML={{ __html: dataFooter.Title }}
                />
                <p
                  dangerouslySetInnerHTML={{ __html: dataFooter.Descripton }}
                />
                <Link className="our-app-btn btn mt-4" to="/download">
                  Get App
                </Link>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Footer;
