import React from "react";
import Navbar from "../component/Navbar";
import { useSelector } from "react-redux";
import { getPoliciesSelector } from "../redux/selectors";
import Company from "../component/Company";

const PoliciesPage: React.FC = () => {
  const policiesData = useSelector(getPoliciesSelector);
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <div className="bg-green">
        <div className="container">
          <Navbar hiddenMenu />
        </div>
      </div>
      <div className="container flex-grow-1">
        {policiesData ? (
          <div
            className="text-left"
            dangerouslySetInnerHTML={{ __html: policiesData.Detail }}
          />
        ) : (
          <p className="mt-4 text-center">Không có dữ liệu.</p>
        )}
      </div>
      <Company />
    </div>
  );
};

export default PoliciesPage;
