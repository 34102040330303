import { createSlice } from "@reduxjs/toolkit";
import { TermsType } from "../../types";

export interface State {
  data: TermsType | undefined;
}

const initialState: State = { data: undefined };

export const termsSlice = createSlice({
  name: "terms",
  initialState,
  reducers: {
    setTerms: (state, action: { type: string; payload: TermsType }) => {
      state.data = action.payload;
    },
  },
});

export const { setTerms } = termsSlice.actions;

export default termsSlice.reducer;
