import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAppInfoSelector } from "../redux/selectors";
import Loader from "../component/Loader";

const DownloadPage: React.FC = () => {
  const navigate = useNavigate();
  const linkIOs = useSelector(getAppInfoSelector)?.URLIOS;
  const linkAndroid = useSelector(getAppInfoSelector)?.URLAndroid;

  const handleCheckDevice = () => {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
      return "Android";
    } else if (
      /iPad|iPhone|iPod/.test(ua) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    ) {
      return "iOS";
    } else {
      return "browser";
    }
  };

  const handleRedirectHomePage = () => {
    setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  useEffect(() => {
    const resultCheck = handleCheckDevice();
    if (resultCheck === "Android") {
      if (linkAndroid) {
        window.location.replace(linkAndroid);
        handleCheckDevice();
      }
    } else if (resultCheck === "iOS") {
      if (linkIOs) {
        window.location.replace(linkIOs);
        handleRedirectHomePage();
      }
    }
    handleRedirectHomePage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Loader />
    </div>
  );
};

export default DownloadPage;
