// API GET APP'S INFORMATION

import axiosInstance from "../axiosInstance";

const appInfoAPI = () => {
  return axiosInstance.post("/app-info", {
    source: "my-wifi",
  });
};

export default appInfoAPI;
