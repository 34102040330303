import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://banthe.app/apimobi/v1",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: "wi-mesh",
    "wm-token": "wi-mesh",
    "wm-device": "wi-mesh",
    "wm-time": "wi-mesh",
  },
});

export default axiosInstance;
