import React from "react";
import Navbar from "../component/Navbar";
import { useSelector } from "react-redux";
import { getFAQsSelector } from "../redux/selectors";
import Company from "../component/Company";
import FAQs from "../component/FAQs";

const FAQsPage: React.FC = () => {
  const faqsData = useSelector(getFAQsSelector);

  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <div className="bg-green">
        <div className="container">
          <Navbar hiddenMenu />
        </div>
      </div>
      <div className="container flex-grow-1">
        {faqsData ? (
          <FAQs FAQsData={faqsData} />
        ) : (
          <p className="mt-4 text-center">Không có dữ liệu.</p>
        )}
      </div>
      <Company />
    </div>
  );
};

export default FAQsPage;
