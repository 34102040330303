import { createSlice } from "@reduxjs/toolkit";
import { AppInfoType } from "../../types";

export interface State {
  data: AppInfoType | undefined;
}

const initialState: State = { data: undefined };

export const appInfoSlice = createSlice({
  name: "appInfo",
  initialState,
  reducers: {
    setAppInfo: (state, action: { type: string; payload: AppInfoType }) => {
      state.data = action.payload;
    },
  },
});

export const { setAppInfo } = appInfoSlice.actions;

export default appInfoSlice.reducer;
