import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LandingPage from "./pages/LandingPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TermsPage from "./pages/TermsPage";
import { appInfoAPI, faqsAPI, policiesAPI, termsAPI } from "./services";
import PoliciesPage from "./pages/PoliciesPage";
import Loader from "./component/Loader";
import { setLoading } from "./redux/slices/loadingSlice";
import { getIsLoadingSelector } from "./redux/selectors";
import { setTerms } from "./redux/slices/termsSlice";
import { setPolicies } from "./redux/slices/policiesSlice";
import { setAppInfo } from "./redux/slices/appInfoSlice";
import DownloadPage from "./pages/DownloadPage";
import ButtonGoToTop from "./component/ButtonGoToTop";
import { setFAQs } from "./redux/slices/faqsSlice";
import FAQsPage from "./pages/FAQsPage";
import LoginWifiPage from "./pages/LoginWifiPage";

const App: React.FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoadingSelector);

  const handleGetTermsData = async () => {
    dispatch(setLoading(true));
    try {
      const resultTermsApi = await termsAPI();
      if (resultTermsApi.data.result) {
        dispatch(setTerms(resultTermsApi.data.data));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleGetPoliciesData = async () => {
    dispatch(setLoading(true));
    try {
      const resultPoliciesApi = await policiesAPI();
      if (resultPoliciesApi.data.result) {
        dispatch(setPolicies(resultPoliciesApi.data.data));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleGetAppInfo = async () => {
    dispatch(setLoading(true));
    try {
      const resultAppInfoApi = await appInfoAPI();
      if (resultAppInfoApi.data.result) {
        dispatch(setAppInfo(resultAppInfoApi.data.data));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleGetFAQs = async () => {
    dispatch(setLoading(true));
    try {
      const resultFAQs = await faqsAPI();
      if (resultFAQs.data.result) {
        dispatch(setFAQs(resultFAQs.data.data));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    handleGetTermsData();
    handleGetPoliciesData();
    handleGetFAQs();
    handleGetAppInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      {isLoading ? (
        <Loader />
      ) : (
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/policies" element={<PoliciesPage />} />
          <Route path="/FAQs" element={<FAQsPage />} />
          <Route path="/download" element={<DownloadPage />} />
          <Route path="/loginWifi/*" element={<LoginWifiPage />} />
        </Routes>
      )}
      <ButtonGoToTop />
    </BrowserRouter>
  );
};

export default App;
